import styled from "styled-components";
import { BothCardSidesTB, innerCardLefttMixin, BothCardSidesLR, innerCardRightMixin, innerMiddleMixin, cardMixin} from "../mixin"; 
import { breakpoints } from "@/shared/Constants";

//MODULO 5 pisos reutilizable
export const Parent = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 314px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    border-top: 1px solid  #E0E0E0;
    padding-top:20px;
    margin-top:10px;
      .div1 { grid-area: 1 / 1 / 3 / 2; }
      .div2 { grid-area: 1 / 2 / 3 / 3; }
      .div3 { grid-area: 3 / 1 / 5 / 3; }
      .div4 { grid-area: 5 / 1 / 7 / 2; }
      .div5 { grid-area: 5 / 2 / 6 / 3; }
      .div6 { grid-area: 6 / 2 / 7 / 3; }
      .div7 { grid-area: 7 / 1 / 9 / 2; }
      .div8 { grid-area: 7 / 2 / 9 / 3; }
      .div9 { grid-area: 9 / 1 / 11 / 2; }
      .div10 { grid-area: 9 / 2 / 11 / 3; 
        > div {
            align-items: start;
        }
      }
      .div11 { grid-area: 9 / 3 / 11 / 4; }
      .div12 { grid-area: 9 / 4 / 11 / 5;
       > div {
            align-items: start;
        }
      }
      .div13 { grid-area: 1 / 3 / 9 / 4; }
      .div14 { grid-area: 1 / 4 / 5 / 5; 
        max-height:640px;
        overflow:hidden;
        &.contentWrap{
          max-height:930px;
        }
      }
      .div15 { grid-area: 5 / 4 / 7 / 5; }
      .div16 { grid-area: 7 / 4 / 9 / 5; border-bottom: 1px solid #E0E0E0;}
    ${breakpoints.tablet} {
      >div:before, >div:after{display:none}
      .div1 { grid-area: 1 / 1 / 3 / 2; }
      .div2 { grid-area: 1 / 2 / 3 / 3; }
      .div3 { grid-area: 3 / 1 / 5 / 3; }
      .div4 { grid-area: 5 / 1 / 7 / 2; }
      .div5 { grid-area: 5 / 2 / 6 / 3; }
      .div6 { grid-area: 6 / 2 / 7 / 3; }
      .div7 { grid-area: 7 / 1 / 9 / 2; }
      .div8 { grid-area: 7 / 2 / 9 / 3; }
      .div9 { grid-area: 11 / 2 / 13 / 3; }
      .div10 { grid-area: 9 / 1 / 11 / 2; }
      .div11 { grid-area: 9 / 2 / 11 / 3; }
      .div12 { grid-area: 9 / 3 / 11 / 4; }
      .div13 { grid-area: 1 / 3 / 9 / 4; }
      .div14 { grid-area: 11 / 1 / 15 / 2;
        max-height:485px;
        &.contentWrap{
          max-height:526px;
        }
      }
      .div15 { grid-area: 11 / 3 / 13 / 4; }
      .div16 {
        grid-area: 13 / 2 / 15 / 4;
        border-bottom: 0 none;
        .onexone {
          width: 100%;
          flex-direction: row-reverse;
          picture{
            width: 307px;
          }
          .mt {
            flex: 1;
            width: 50%;
            margin-left: 20px;
            h2.title {
              font-size: 24px;
              line-height: 26px;
              margin-bottom: 15px;
              ${breakpoints.phone} {
                font-size: 22px;
                line-height: 23px;
              }
            }
            .volanta {
              font-size: 24px;
              line-height: 26px;
              ${breakpoints.phone} {
                font-size: 22px;
                line-height: 23px;
              }
            }
            .bajada {
              margin: 2px 0 15px;
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }
    ${breakpoints.phone}{
      width:100%;
      display:flex;
      flex-direction: column;
    }    
`
export const Card = styled.div`
${cardMixin};
`;

export const InnerCardL = styled.div`
  ${innerCardLefttMixin}
`;

export const InnerCardR = styled.div`
${innerCardRightMixin};
`;

export const InnerCardM = styled.div`
${innerMiddleMixin};
`;


export const BothCard = styled.div`
${BothCardSidesLR};
`;

export const BothCardTop = styled.div`
  ${BothCardSidesTB}
`;