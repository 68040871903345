import Separator from "@/shared/Separator";
import Container5Pisos from "../Container5Pisos";
import Container2Pisos from "../Container2Pisos";
import { GranParent} from "./style";
const Container7Pisos = (props) => {
  const { link, sectionClass = "", title, title1, link1, title2, link2, title3, link3, title4, link4, linkImage } = props;

  const titlesExtras = [];
  if(title1 && link1) titlesExtras.push({title: title1, link: link1})
  if(title2 && link2) titlesExtras.push({title: title2, link: link2})
  if(title3 && link3) titlesExtras.push({title: title3, link: link3})
  if(title4 && link4) titlesExtras.push({title: title4, link: link4})

  const marfeelTitle = title ? "container7pisos-"+title?.normalize("NFD")
  .replace(/[\u0300-\u036f]/g, "")
  .replace(/\s+/g, '-')
  .toLowerCase() : "container7pisos";

  return (
    props.content?.length > 0 && <>
      {title && <Separator title={title} link={link} sectionClass={sectionClass} extras={titlesExtras} linkImage={linkImage} />}
      <GranParent title={title} data-mrf-recirculation={`${marfeelTitle}`} className="ContainerPiso7"  id={marfeelTitle}>
        <Container2Pisos titleEnabled={false} marfeelEnabled={false} {...props}/>
          <span className="line"></span>
        <Container5Pisos index={6} titleEnabled={false} marfeelEnabled={false} {...props}/>
      </GranParent >
    </>
  );
};
export default Container7Pisos;